export class Constants {
  public static readonly imageArAi = 'logo-AR-AI.png';
  public static readonly imageBe = 'Kanton_Bern_RGB_Positiv.jpg';
  public static readonly imageFr1 = 'logo-FR.png';
  public static readonly imageFr2 = 'SAS_SIS_rgb_28.png';
  public static readonly imageFl = 'logo-FL.png';
  public static readonly imageAg = 'logo-AG.png';
  public static readonly imageZh1 = 'logo-ZH.png';
  public static readonly imageZh2 = 'logo-ZH-2.png';
  public static readonly imageVfrCl = 'logo-vfr-cl.jpg';
  public static readonly imageVfrCl2 = 'logo-vfr-cl-oic.jpg';
  public static readonly imageUrSzOwNw = 'logo-UrSzOwNw.png';
  public static readonly centerIdentifierAg = 'ag';
  public static readonly centerIdentifierArAi = 'aiar';
  public static readonly centerIdentifierAr = 'ar';
  public static readonly centerIdentifierAi = 'ai';
  public static readonly centerIdentifierBe = 'be';
  public static readonly centerIdentifierFr = 'fr';
  public static readonly centerIdentifierFl = 'fl';
  public static readonly centerIdentifierZh = 'zh';
  public static readonly centerIdentifierUr = 'ur';
  public static readonly centerIdentifierNw = 'nw';
  public static readonly centerIdentifierOw = 'ow';
  public static readonly centerIdentifierSz = 'sz';
  public static readonly protocolStandardTemplateNameAg = 'AG_protocol_standard';
  public static readonly protocolStandardTemplateNameAgBienen = 'AG_protocol_bienen';
  public static readonly protocolStandardTemplateNameArAi = 'ARAI_protocol_standard';
  public static readonly protocolTemplateNameArAiCertificate = 'ARAI_protocol_certificate';
  public static readonly protocolStandardTemplateNameBe = 'BE_protocol_standard';
  public static readonly protocolTemplateNameBeDhbVertragskantone =
    'BE_protocol_dhbVertragskantone';
  public static readonly protocolStandardTemplateNameFl = 'FL_protocol_standard';
  public static readonly protocolStandardTemplateNameFr = 'FR_protocol_standard';
  public static readonly protocolTemplateNameFrViehhandel = 'FR_protocol_viehhandel';
  public static readonly protocolTemplateNameFrGruyere = 'FR_protocol_gruyere';
  public static readonly protocolTemplateNameFrVacherin = 'FR_protocol_vacherin';
  public static readonly protocolStandardTemplateNameUr = 'URK_protocol_standard';
  public static readonly protocolStandardTemplateNameZh = 'ZH_protocol_standard';
  public static readonly protocolStandardTemplateNameZhTnp = 'ZH_protocol_standard_tnp';
  public static readonly protocolTemplateNameZhPrivatePharmacies = 'ZH_protocol_privatepharmacies';
}
