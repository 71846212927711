import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';
import { ImagesDto } from '../../dtos/imagesDto';
import { MKDEUserDto } from '../api/model/mKDEUserDto';
import { InspectionCategoryCacheService } from '../cache/inspection-category-cache-service';
import { Constants } from '../constants';
import { UserDBService } from '../db/user-db-service';
import { I18nTextPipe } from '../pipes/i18n-text.pipe';
import { ProtocolPdfAgService } from './protocol-pdf-ag.service';
import { ProtocolPdfAgBienenService } from './protocol-pdf-ag-bienen.service';
import { ProtocolPdfArAiService } from './protocol-pdf-ar-ai.service';
import { ProtocolPdfBeService } from './protocol-pdf-be.service';
import { ProtocolPdfFlService } from './protocol-pdf-fl.service';
import { ProtocolPdfFrService } from './protocol-pdf-fr.service';
import { ProtocolPdfFrViehhandelService } from './protocol-pdf-fr-viehhandel.service';
import { ProtocolPdfZhService } from './protocol-pdf-zh.service';
import { ProtocolPdfZhTnpService } from './protocol-pdf-zh-tnp.service';
import { ProtocolPdfFrGruyereService } from './protocol-pdf-fr-gruyere.service';
import { ProtocolPdfFrVacherinService } from './protocol-pdf-fr-vacherin.service';
import { ProtocolPdfUrSzOwNwService } from './protocol-pdf-ur-sz-ow-nw.service';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { InspectionResultsService } from './inspection-results-service';
import { ProtocolPdfZhPrivatePharmaciesService } from './protocol-pdf-zh-private-pharmacies.services';
import { ProtocolPdfArAiCertificateService } from './protocol-pdf-ar-ai-certificate.service';
import { ProtocolPdfBeDhbVertragskantoneService } from './protocol-pdf-be-dhb-vertragskantone.service';
import { ImageService } from './image-service';

@Injectable({
  providedIn: 'root',
})
export class ProtocolPdfService {
  public constructor(
    private userDbService: UserDBService,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private i18nTextPipe: I18nTextPipe,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
    private imageService: ImageService,
    private inspectionResultsService: InspectionResultsService
  ) {}

  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto): any {
    this.imageService.getImages(protocolPdfDto.templateName).subscribe((pdfImages: ImagesDto) => {
      let protocolPdfService = null;
      switch (protocolPdfDto.templateName) {
        case Constants.protocolStandardTemplateNameArAi:
          protocolPdfService = new ProtocolPdfArAiService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameBe:
          protocolPdfService = new ProtocolPdfBeService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolTemplateNameBeDhbVertragskantone:
          protocolPdfService = new ProtocolPdfBeDhbVertragskantoneService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameFr:
          protocolPdfService = new ProtocolPdfFrService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolTemplateNameFrViehhandel:
          protocolPdfService = new ProtocolPdfFrViehhandelService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolTemplateNameFrGruyere:
          protocolPdfService = new ProtocolPdfFrGruyereService(
            this.translateService,
            protocolPdfDto.inspection,
            pdfImages,
            this.datePipe
          );
          protocolPdfService.generateProtocolPdf();
          break;
        case Constants.protocolTemplateNameFrVacherin:
          protocolPdfService = new ProtocolPdfFrVacherinService(
            this.translateService,
            protocolPdfDto.inspection,
            pdfImages,
            this.datePipe
          );
          protocolPdfService.generateProtocolPdf();
          break;
        case Constants.protocolStandardTemplateNameFl:
          protocolPdfService = new ProtocolPdfFlService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameAg:
          protocolPdfService = new ProtocolPdfAgService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameAgBienen:
          protocolPdfService = new ProtocolPdfAgBienenService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolTemplateNameArAiCertificate:
          protocolPdfService = new ProtocolPdfArAiCertificateService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameZh:
          protocolPdfService = new ProtocolPdfZhService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolTemplateNameZhPrivatePharmacies:
          protocolPdfService = new ProtocolPdfZhPrivatePharmaciesService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameZhTnp:
          protocolPdfService = new ProtocolPdfZhTnpService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        case Constants.protocolStandardTemplateNameUr:
          protocolPdfService = new ProtocolPdfUrSzOwNwService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
          break;
        default:
          protocolPdfService = new ProtocolPdfBeService(
            this.translateService,
            this.inspectionCategoryCacheService,
            this.i18nTextPipe,
            this.datePipe,
            this.decimalPipe,
            pdfImages,
            this.inspectionResultsService
          );
          protocolPdfService.generateProtocolPdf(protocolPdfDto);
      }
    });
  }

  public getProtocolPdfTemplates(): Observable<string[]> {
    return this.getCenterIdentifier().pipe(
      concatMap((centerIdentifier: string) => {
        const pdfProtocolTemplates: string[] = [];
        switch (centerIdentifier) {
          case Constants.centerIdentifierArAi:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameArAi);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameArAiCertificate);
            break;
          case Constants.centerIdentifierBe:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameBe);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameBeDhbVertragskantone);
            break;
          case Constants.centerIdentifierFr:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameFr);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameFrVacherin);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameFrGruyere);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameFrViehhandel);
            break;
          case Constants.centerIdentifierFl:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameFl);
            break;
          case Constants.centerIdentifierAg:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameAg);
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameAgBienen);
            break;
          case Constants.centerIdentifierUr:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameUr);
            break;
          case Constants.centerIdentifierZh:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameZh);
            pdfProtocolTemplates.push(Constants.protocolTemplateNameZhPrivatePharmacies);
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameZhTnp);
            break;
          default:
            pdfProtocolTemplates.push(Constants.protocolStandardTemplateNameBe);
        }
        return of(pdfProtocolTemplates);
      })
    );
  }

  private getCenterIdentifier(): Observable<string> {
    return this.userDbService
      .getUser()
      .pipe(concatMap((user: MKDEUserDto) => this.getLoadedCenterIdentifier(user)));
  }

  private getLoadedCenterIdentifier(user: MKDEUserDto): Observable<string> {
    const centerIdentifier = user.centerIdentifier;
    let result = Constants.centerIdentifierBe;
    if (
      centerIdentifier.contains('vet') &&
      centerIdentifier.contains(Constants.centerIdentifierAi, Constants.centerIdentifierAr)
    ) {
      result = Constants.centerIdentifierArAi;
    } else if (
      centerIdentifier.contains('vet') &&
      centerIdentifier.contains(Constants.centerIdentifierBe)
    ) {
      result = Constants.centerIdentifierBe;
    } else if (
      centerIdentifier.contains('scav') &&
      centerIdentifier.contains(Constants.centerIdentifierFr)
    ) {
      result = Constants.centerIdentifierFr;
    } else if (
      centerIdentifier.contains('scav') &&
      centerIdentifier.contains(Constants.centerIdentifierFl)
    ) {
      result = Constants.centerIdentifierFl;
    } else if (
      centerIdentifier.contains('vet') &&
      centerIdentifier.contains(Constants.centerIdentifierAg)
    ) {
      result = Constants.centerIdentifierAg;
    } else if (
      centerIdentifier.contains('vet') &&
      centerIdentifier.contains(Constants.centerIdentifierZh)
    ) {
      result = Constants.centerIdentifierZh;
    } else if (
      centerIdentifier.contains('vet') &&
      centerIdentifier.contains(Constants.centerIdentifierUr)
    ) {
      result = Constants.centerIdentifierUr;
    } else if (
      centerIdentifier.contains('scav') &&
      (centerIdentifier.contains(Constants.centerIdentifierNw) ||
        centerIdentifier.contains(Constants.centerIdentifierOw) ||
        centerIdentifier.contains(Constants.centerIdentifierSz) ||
        centerIdentifier.contains(Constants.centerIdentifierUr))
    ) {
      result = Constants.centerIdentifierUr;
    } else {
      result = Constants.centerIdentifierBe;
    }
    return of(result);
  }
}
