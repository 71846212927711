import { ProtocolPdfService } from '../core/protocol-pdf/protocol-pdf.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InspectionDBService } from '../core/db/inspection-db-service';
import { TranslateService } from '@ngx-translate/core';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import { EnumCacheService } from '../core/cache/enum-cache-service';
import { ProtocolDto } from '../dtos/protocolDto';
import { ProtocolDBService } from '../core/db/protocol-db-service';
import { DefectDto } from '../dtos/defectDto';
import * as _ from 'underscore';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { StatusChangeValidationService } from '../core/status-change-validation.service';
import { StatusChangeDialogComponent } from '../status-change-dialog/status-change-dialog.component';
import { ConfirmOverrideDialogComponent } from '../confirm-override-dialog/confirm-override-dialog.component';
import { Observable, Observer, Subscription, of } from 'rxjs';
import { AggregatedDefectDto } from '../dtos/aggregatedDefectDto';
import { InspectionElementLinkedDto } from '../dtos/InspectionElementLinkedDto';
import { DefectDialogComponent } from './defect-dialog.component';
import { ClientConfigDBService } from '../core/db/client-config-db-service';
import { ProtocolFeeDto } from '../dtos/protocolFeeDto';
import { CategoryConfig } from '../core/api/model/categoryConfig';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionRemarkSuggestion } from '../core/api/model/inspectionRemarkSuggestion';
import { InspectionTypeConfig } from '../core/api/model/inspectionTypeConfig';
import { InspectionWithResultsDto } from '../core/api/model/inspectionWithResultsDto';
import { Point } from '../core/api/model/point';
import { PointConfig } from '../core/api/model/pointConfig';
import { Position } from '../core/api/model/position';
import { ClientConfig } from '../core/api/model/clientConfig';
import '../core/util/string.extension';
import '../core/util/date.extension';
import { OkCancelDialogComponent } from '../ok-cancel-dialog/ok-cancel-dialog.component';
import { NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { I18nTextListPipe } from '../core/pipes/i18n-text-list.pipe';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { concatMap } from 'rxjs/operators';
import { PointGroupType } from '../core/api/model/pointGroupType';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { InspectionResultElementType } from '../core/api/model/inspectionResultElementType';
import { TranslationText } from '../core/api/model/translationText';
import { InspectionStyleDto, InspectionStatus, InspectionStatusDto } from '@core/api';
import { Constants } from '@core/constants';
import { keysToTranslate } from '@core/util/keys-to-translate';
import { ProtocolStatementDto } from '../dtos/protocolStatementDto';
import { ProtocolFurtherActionDto } from '../dtos/protocolFurtherActionDto';

interface II18NText {
  frenchText?: FormControl<string>;
  germanText?: FormControl<string>;
  italianText?: FormControl<string>;
}

interface IForm {
  inspectionKey: string;
  defects: FormArray<FormGroup>;
  furtherAction: FormGroup;
  inspection: FormGroup;
  fees: FormGroup;
  statement: FormGroup;
}

@Component({
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.scss'],
})
export class ProtocolComponent implements OnInit, OnDestroy {
  public inspectionWithResult: InspectionWithResultsDto;
  public protocol: ProtocolDto;
  public defects: DefectDto[];
  public protocolForm: FormGroup;
  public inspectionStyles: InspectionStyleDto[];
  public inspectionDetailsCollapsed = true;
  public inspectionResultCollapsed = false;
  public furtherActionCollapsed = true;
  public defectsDetailsCollapsed: Map<number, boolean> = new Map<number, boolean>();
  public statementActionCollapsed = false;
  public feesCollapsed = true;
  public readonly = false;
  public configProtocolFeesType: I18NText[] = [];
  public configProtocolFurtherActions: I18NText[] = [];
  public configProtocolNotifications: I18NText[] = [];
  public configProtocolInspectionRemarkSuggestions: I18NText[] = [];
  public hasFees: boolean;

  private subscriptions: Subscription[] = [];
  private configProtocolFees: ProtocolFeeDto[];
  private allCategories: InspectionElementDto[];
  private allCategoriesChain: InspectionElementLinkedDto[];
  private allowedNotControlledPointsInControlledCategory: InspectionElementDto[];
  private statusChanged = false;
  private pointConfig: PointConfig;
  private inspectionTypeConfig: InspectionTypeConfig;
  private clientConfig: ClientConfig;
  private selectedProtocolPdfTemplate: string;

  public constructor(
    private route: ActivatedRoute,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private enumCacheService: EnumCacheService,
    private inspectionDBService: InspectionDBService,
    private protocolDBService: ProtocolDBService,
    private translateService: TranslateService,
    private alerts: MkdeAlertHolderDirective,
    private i18nTextListPipe: I18nTextListPipe,
    private i18nTextPipe: I18nTextPipe,
    private modalService: NgbModal,
    private statusChangeValidationService: StatusChangeValidationService,
    private formBuilder: FormBuilder,
    private clientConfigDBService: ClientConfigDBService,
    private protocolPdfService: ProtocolPdfService
  ) {
    keysToTranslate([
      'Mangel.Massnahme',
      'Mangel.Frist',
      'Ausweisen',
      'Protokolltext',
      'Kontrolle.KontrollierterBereich',
      'Kontrolle.Kontrollbeginn',
      'Kontrolle.Kontrollende',
      'Kontrolle.Art',
      'Kontrolle.Kontrolleur',
      'Kontrolle.KontrollierterStandort',
      'Bemerkung ausweisen',
      'Kontrolle.Bemerkung',
      'Stellungnahme.Bemerkung',
    ]);
  }

  public get fees() {
    return this.protocolForm.get('fees') as FormGroup;
  }
  public get feesPositionArray() {
    return this.fees.get('positions') as FormArray;
  }
  public get furtherAction() {
    return this.protocolForm.get('furtherAction') as FormGroup;
  }
  public get inspection() {
    return this.protocolForm.get('inspection') as FormGroup;
  }
  public get defectArray() {
    return this.protocolForm.get('defects') as FormArray;
  }
  public get statement() {
    return this.protocolForm.get('statement') as FormGroup;
  }

  public ngOnInit(): void {
    this.inspectionWithResult = this.route.snapshot.data['inspection'] as InspectionWithResultsDto;
    this.protocol = this.route.snapshot.data['protocol'] as ProtocolDto;
    this.allCategories = this.route.snapshot.data['inspectionCategories'] as InspectionElementDto[];
    this.buildInspectionCategoriesChain(this.allCategories);
    this.pointConfig = this.route.snapshot.data['pointConfig'] as PointConfig;
    this.inspectionTypeConfig = this.route.snapshot.data[
      'inspectionTypeConfig'
    ] as InspectionTypeConfig;
    this.allowedNotControlledPointsInControlledCategory =
      this.getAllowedNotControlledPointsInControlledCategory(this.allCategories);

    // Initialize caches in case the user reloads the application on the protocol view.
    this.initializeCaches();
    this.initializeProtocol();
    this.buildDefects();
    this.createProtocolForm();
    this.initializeFurtherActionControlsChanges();
    this.saveProtocol();
    this.toggleStatementControls();

    this.inspectionStyles = this.enumCacheService.getInspectionStyles();
    this.readonly = this.isStatusEntered();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public createProtocolForm(): void {
    this.protocolForm = this.formBuilder.group<IForm>({
      inspectionKey: this.inspectionWithResult.inspectionKey,
      defects: this.createDefects(),
      furtherAction: this.formBuilder.group<ProtocolFurtherActionDto>({
        noFurtherAction: this.ensureBooleanValue(this.protocol.furtherAction.noFurtherAction),
        repairsIndependently: this.ensureBooleanValue(
          this.protocol.furtherAction.repairsIndependently
        ),
        feedback: this.ensureBooleanValue(this.protocol.furtherAction.feedback),
        inspectionReport: this.ensureBooleanValue(this.protocol.furtherAction.inspectionReport),
        order: this.ensureBooleanValue(this.protocol.furtherAction.order),
        criminalComplaint: this.ensureBooleanValue(this.protocol.furtherAction.criminalComplaint),
        followupInspection: this.ensureBooleanValue(this.protocol.furtherAction.followupInspection),
        followupInspectionDate: this.ensureDateValue(
          this.protocol.furtherAction.followupInspectionDate
        ),
        notification: this.ensureBooleanValue(this.protocol.furtherAction.notification),
        notificationText: this.protocol.furtherAction.notificationText,
        furtherAction: this.ensureBooleanValue(this.protocol.furtherAction.furtherAction),
        furtherActionText: this.protocol.furtherAction.furtherActionText,
      }),
      inspection: this.formBuilder.group<ProtocolDto>({
        inspectionStartDate: this.getNgbDate(this.protocol.inspectionStartDate),
        inspectionEndDate: this.getNgbDate(this.protocol.inspectionEndDate),
        controlledLocation: this.protocol.controlledLocation,
        controlledArea: this.getControlledArea(),
        displayFieldValueElements: this.getDisplayFieldValueElements(),
        inspectionStyle: this.inspectionWithResult.inspectionStyle,
        inspectorName: this.inspectionWithResult.inspectorName,
        inspectionRemark: this.inspectionWithResult.inspectionRemark,
        displayInspectionRemarkInProtocolPdf: this.protocol.displayInspectionRemarkInProtocolPdf,
        inspectionDate: [
          this.ensureDateValue(this.inspectionWithResult.inspectionDate),
          Validators.required,
        ],
        inspectionKey: this.protocol.inspectionKey,
        feesSelection: this.protocol.fees.feesSelection,
        furtherAction: this.protocol.furtherAction,
        fees: this.protocol.fees,
        statement: this.protocol.statement,
        defects: this.protocol.defects,
      }),
      fees: this.formBuilder.group({
        feesSelection: this.protocol.fees.feesSelection,
        positions: this.getFeePositionsFormArray(),
        totalFee: this.protocol.fees.totalFee,
        displayFeesOnProtocol: this.protocol.fees.displayFeesOnProtocol,
        feesTypes: this.protocol.fees.feesTypes,
      }),
      statement: this.formBuilder.group<ProtocolStatementDto>({
        correspondToFacts: this.ensureBooleanValue(this.protocol.statement.correspondToFacts),
        noCorrespondToFacts: this.ensureBooleanValue(this.protocol.statement.noCorrespondToFacts),
        agree: this.ensureBooleanValue(this.protocol.statement.agree),
        disagree: this.ensureBooleanValue(this.protocol.statement.disagree),
        remark: this.protocol.statement.remark,
      }),
    });
    this.loadClientConfig();
  }

  public onFeesSelectionChange(): void {
    if (this.fees.controls.feesSelection.value === 0) {
      const emptyFees = (feeGroup: FormGroup): void => {
        feeGroup.controls.hoursFee.setValue(0);
        feeGroup.controls.hours.setValue(0);
        feeGroup.controls.hoursReason.setValue(null);
      };

      this.iterateFeeGroups(emptyFees);
      this.fees.controls.totalFee.setValue(0);
    }
  }

  public clickDefectDetailsCollapse(defectIndex: number) {
    let collapse = false;
    if (!this.defectsDetailsCollapsed.has(defectIndex)) {
      collapse = true;
      this.defectsDetailsCollapsed.set(defectIndex, collapse);
    } else {
      const defectDetailCollapse = this.defectsDetailsCollapsed.get(defectIndex);
      collapse = !defectDetailCollapse;
      this.defectsDetailsCollapsed.set(defectIndex, collapse);
    }

    const defectGroup = this.defectArray.controls[defectIndex] as FormGroup;
    if (defectGroup === null) {
      return;
    }
    defectGroup.value.detailsCollapsed = collapse;
  }

  public getDefectDetailsCollapse(defectIndex: number): boolean {
    if (this.defectsDetailsCollapsed.has(defectIndex)) {
      const defectDetailCollapse = this.defectsDetailsCollapsed.get(defectIndex);
      return defectDetailCollapse;
    }

    return true;
  }

  public isDefectSancionSuggestionEmpty(defectIndex: number) {
    const defectSanctionSuggestions = this.getDefectSanctionSuggestions(defectIndex);
    return (
      defectSanctionSuggestions === null ||
      defectSanctionSuggestions.value === null ||
      defectSanctionSuggestions.value.length <= 0
    );
  }
  public getDefectSanctionSuggestions(defectIndex: number): FormArray {
    const defectGroup = this.defectArray.controls[defectIndex] as FormGroup;
    if (typeof defectGroup === 'undefined' || defectGroup === null) {
      return null;
    }

    return defectGroup.controls.sanctionSuggestions as FormArray;
  }

  public getDefectSanctionSuggestionTranslation(defectIndex: number): I18NText[] {
    const defectSanctionSuggestions = this.getDefectSanctionSuggestions(defectIndex);
    return defectSanctionSuggestions.value as I18NText[];
  }

  public onFeeChanged(feeIndex: number): void {
    const fee = this.getPositionAsDto(feeIndex);
    const feeGroup = this.getFeePositionFormGroupByindex(feeIndex);
    if (!fee.selected) {
      feeGroup.controls.hoursFee.setValue(0);
      feeGroup.controls.hours.setValue(0);
      feeGroup.controls.hoursReason.setValue(null);
    } else {
      feeGroup.controls.hoursFee.setValue(this.getRateByIndex(feeIndex));
      feeGroup.controls.hours.setValue(1);
    }
    this.updateFeesTotal();
  }

  public onHoursReasonChanged(value, index): void {
    this.protocol.fees.positions[index].hoursReason = value;
  }

  public onFeesHoursChange(feeIndex: number): void {
    const feeGroup = this.getFeePositionFormGroupByindex(feeIndex);
    const hours = +this.convertToFloat(feeGroup.controls.hours.value);
    const rate = +this.convertToFloat(feeGroup.controls.rate.value);

    if (hours > 0) {
      feeGroup.controls.hoursFee.setValue(this.convertToFloat(hours * rate));
    } else {
      feeGroup.controls.hours.setValue(0);
      feeGroup.controls.hoursFee.setValue(0);
      feeGroup.controls.hoursReason.setValue(null);
    }
    this.updateFeesTotal();
  }

  public saveProtocolAndInspection(): void {
    if (this.protocolForm.valid) {
      this.saveProtocol();
      this.saveInspection();
    }
  }

  public getDefectLabel(defect: DefectDto): string {
    return (
      defect.inspectionElementNames
        .map((i18nName) => this.i18nTextPipe.transform(i18nName))
        .join(' - ') +
      ' - ' +
      defect.pointNumber
    );
  }

  public openDefectDialog(defectIndex: number): void {
    const modal = this.modalService.open(DefectDialogComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modal.componentInstance.defectForm = this.defectArray.controls[defectIndex];
    modal.componentInstance.readonly = this.readonly;
    modal.componentInstance.defectChange.subscribe(() => {
      this.saveInspection();
    });
  }

  public insertSanction(defectIndex: number, sanctionSuggestion: I18NText): void {
    const defectGroup = this.defectArray.controls[defectIndex] as FormGroup;
    if (defectGroup === null) {
      return;
    }

    const defectResult = defectGroup.controls.result as FormGroup;
    if (defectResult === null) {
      return;
    }
    const suggestion = this.i18nTextPipe.transform(sanctionSuggestion);
    const value = defectResult.controls.remark.value
      ? defectResult.controls.remark.value + ', '
      : '';
    defectResult.controls.remark.setValue(value + suggestion);
  }

  public onProtocolPdfTemplateSelected(selectedProtocolPdfTemplate: string): void {
    this.selectedProtocolPdfTemplate = selectedProtocolPdfTemplate;
  }

  public generateProtocolPdf(): void {
    this.protocol.controlledArea = this.getControlledArea();
    this.protocol.displayFieldValueElements = this.getDisplayFieldValueElements();
    this.protocol.fees.feesTypes = { ...this.configProtocolFeesType };
    this.protocolPdfService.generateProtocolPdf({
      language: this.translateService.currentLang,
      templateName: this.selectedProtocolPdfTemplate,
      inspection: this.inspectionWithResult,
      protocol: this.protocol,
      inspectionCategories: this.allCategories,
      displayDefectSeverity: this.displayDefectSeverityInProtocolPdf(),
      displayInspectionReason: this.displayInspectionReason(),
      displayPointGroupNumber: false,
      allowedNotControlledPointsInControlledCategory:
        this.allowedNotControlledPointsInControlledCategory,
    });
  }

  public copyDueDate(dueDate: Date): void {
    for (const defect of this.defectArray.controls) {
      if (!defect.value.result.complaintRemedyDateDue) {
        defect.get('result').get('complaintRemedyDateDue').setValue(dueDate);
      }
    }
  }

  public toggleNotification(): void {
    if (!this.furtherAction.value.notification && this.furtherAction.value.notificationText) {
      setTimeout(() => {
        const modal = this.modalService.open(ConfirmOverrideDialogComponent, { size: 'md' });
        modal.componentInstance.confirmationText = this.translateService.instant(
          'ConfirmOverrideFurtherAction'
        );
        modal.result.then(
          () => {
            this.furtherAction.controls.notificationText.setValue(null);
            this.saveProtocol();
          },
          () => {
            this.furtherAction.controls.notification.setValue(true);
            this.saveProtocol();
          }
        );
      }, 0);
    }
  }

  public onFurtherActionFurtherActionChange(value: I18NText): void {
    this.furtherAction.controls.furtherActionText.setValue(this.i18nTextPipe.transform(value));
  }

  public onInspectionRemarkSuggestionChange(remarkSuggestion: I18NText): void {
    const suggestion = this.i18nTextPipe.transform(remarkSuggestion);
    const value = this.inspection.controls.inspectionRemark.value
      ? this.inspection.controls.inspectionRemark.value + ', '
      : '';
    this.inspection.controls.inspectionRemark.setValue(value + suggestion);
  }

  public onFurtherActionNotificationChange(value: I18NText): void {
    this.furtherAction.controls.notificationText.setValue(this.i18nTextPipe.transform(value));
  }

  public toggleFurtherAction(): void {
    if (!this.furtherAction.value.furtherAction && this.furtherAction.value.furtherActionText) {
      setTimeout(() => {
        const modal = this.modalService.open(ConfirmOverrideDialogComponent, { size: 'md' });
        modal.componentInstance.confirmationText = this.translateService.instant(
          'ConfirmOverrideFurtherAction'
        );
        modal.result.then(
          () => {
            this.furtherAction.controls.furtherActionText.setValue(null);
            this.saveProtocol();
          },
          () => {
            this.furtherAction.controls.furtherAction.setValue(true);
            this.saveProtocol();
          }
        );
      }, 0);
    }
  }

  public isStatusEntered(): boolean {
    return this.inspectionWithResult.inspectionStatus.asEnum === InspectionStatus.Entered;
  }

  public showReadonlyScreenDueToEnteredStatusWarning(): boolean {
    return this.isStatusEntered() && !this.statusChanged;
  }

  public asAggregatedDefect(defect: DefectDto): AggregatedDefectDto {
    return defect instanceof AggregatedDefectDto ? (defect as AggregatedDefectDto) : null;
  }

  public toggleInspectionDetailsCollapsed(): void {
    this.inspectionDetailsCollapsed = !this.inspectionDetailsCollapsed;
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 10);
  }

  public getControlledArea(): string {
    let controlledArea: string;
    if (this.inspectionWithResult.inspectionTypeName) {
      let inspectionTypeName = this.inspectionWithResult.inspectionTypeName;
      if (this.inspectionTypeConfig && this.inspectionTypeConfig.inspectionTypes) {
        const typeConfig = this.inspectionTypeConfig.inspectionTypes.filter(
          (it) => it.acontrolNameDe === this.inspectionWithResult.inspectionTypeName.germanText
        );
        if (typeConfig && typeConfig.length > 0) {
          inspectionTypeName = {
            germanText: typeConfig[0].labelDe,
            frenchText: typeConfig[0].labelFr,
            italianText: null,
          };
        }
      }
      controlledArea = this.i18nTextPipe.transform(inspectionTypeName);
    } else {
      const filteredCategoryResults = this.inspectionWithResult.categoryResults.filter(
        (categoryResult) =>
          categoryResult.results &&
          categoryResult.results.some(
            (result) =>
              (!result.inspectionResult && !result.fieldValue) ||
              result.inspectionResult === InspectionResultType.Defect
          )
      );
      const categoryShortNames =
        this.inspectionCategoryCacheService.getInspectionCategoryShortNames(
          filteredCategoryResults.map((categoryResult) => categoryResult.categoryNumber)
        );
      controlledArea = this.i18nTextListPipe.transform(categoryShortNames);
      if (!controlledArea) {
        controlledArea = this.translateService.instant('Keiner');
      }
    }
    return controlledArea;
  }

  public saveInspectionDate(value: NgbDateStruct | null): void {
    let inspectionDate;
    if (value === null || typeof value === 'undefined') {
      return;
    } else if (typeof value === 'string') {
      const valueAsStr = value as string;
      const convertedDate = valueAsStr.asDate();
      inspectionDate = new Date(
        convertedDate.getFullYear(),
        convertedDate.getMonth(),
        convertedDate.getDate()
      );
    } else {
      inspectionDate = new Date(value.year, value.month, value.day);
    }
    this.inspectionWithResult.inspectionDate = inspectionDate;
    this.saveInspection();
  }

  public changeInspectionStatus(): void {
    if (this.inspectionWithResult.inspectionStatus.asEnum !== InspectionStatus.Entered) {
      this.validateAllocateFeesToProtocolChange()
        .pipe(
          concatMap((success) =>
            success ? this.validateInspectionDateForStatusChange() : of(false)
          ),
          concatMap((success) =>
            success ? this.validateInspectionTimeForStatusChange() : of(false)
          ),
          concatMap((success) =>
            success ? this.validateResultComplaintRemedyDateDueDates() : of(false)
          ),
          concatMap((success) => (success ? this.validateInspectionForStatusChange() : of(false))),
          concatMap((success) => (success ? this.validateFurtherActionNotification() : of(false))),
          concatMap((success) => (success ? this.validateFurtherActionText() : of(false)))
        )
        .subscribe((success) => {
          if (success) {
            this.inspectionWithResult.inspectionStatus = this.getInspectionStatus(
              InspectionStatus.Entered
            );
            this.saveInspection();
            this.statusChanged = true;
          }
        });
    } else {
      this.inspectionWithResult.inspectionStatus = this.getInspectionStatus(
        InspectionStatus.Working
      );
      this.saveInspection();
      this.statusChanged = true;
    }
  }

  public isPrintProtocolPdfEnabled(): boolean {
    if (!this.isStandardOrSpecialProtocolTemplate()) {
      return true;
    }
    return this.isStatusEntered();
  }

  public displaySectionFees(): boolean {
    if (
      this.clientConfig &&
      this.clientConfig.protocol &&
      this.clientConfig.protocol.displaySectionFees !== null
    ) {
      return this.clientConfig.protocol.displaySectionFees;
    }
    return true;
  }

  public displaySectionStatement(): boolean {
    if (
      this.clientConfig &&
      this.clientConfig.protocol &&
      this.clientConfig.protocol.displaySectionStatement !== null
    ) {
      return this.clientConfig.protocol.displaySectionStatement;
    }
    return true;
  }

  public sameEnumValue = (a, b) => (a && b ? a.asEnum === b.asEnum : a === b);

  public sameKey = (a, b) => (a && b ? a.key === b.key : a === b);

  private isStandardOrSpecialProtocolTemplate(): boolean {
    if (
      this.selectedProtocolPdfTemplate === Constants.protocolTemplateNameZhPrivatePharmacies ||
      this.selectedProtocolPdfTemplate === Constants.protocolTemplateNameBeDhbVertragskantone
    ) {
      return true;
    }
    return (
      this.selectedProtocolPdfTemplate != null &&
      this.selectedProtocolPdfTemplate.includes('standard')
    );
  }

  private createDefects(): FormArray {
    const defectArray = this.formBuilder.array<FormGroup>([]);
    this.defects.forEach((defect) => {
      const aggregateDefect = this.asAggregatedDefect(defect);
      defectArray.push(
        this.formBuilder.group({
          label: this.getDefectLabel(defect),
          isAggregate: aggregateDefect !== null,
          inspectionElementNames: this.getDefectInspectionNamesFormArray(defect),
          pointNumber: defect.pointNumber,
          showDefectSeveritySelection: defect.showDefectSeveritySelection,
          sanctionSuggestions: this.getDefectSanctionSuggestionsFormArray(defect),
          detailsCollapsed: aggregateDefect !== null ? aggregateDefect.detailsCollapsed : false,
          defects: this.getDefectSubDefectsFormArray(aggregateDefect),
          result: this.getDefectResultFormGroup(defect),
        })
      );
    });
    return defectArray as FormArray;
  }

  private toggleStatementControls(): void {
    this.subscriptions.push(
      this.statement.controls.correspondToFacts.valueChanges.subscribe((value) => {
        if (this.statement.controls.noCorrespondToFacts.value && value) {
          this.statement.controls.noCorrespondToFacts.setValue(!value);
        }
      })
    );

    this.subscriptions.push(
      this.statement.controls.noCorrespondToFacts.valueChanges.subscribe((value) => {
        if (this.statement.controls.correspondToFacts.value && value) {
          this.statement.controls.correspondToFacts.setValue(!value);
        }
      })
    );

    this.subscriptions.push(
      this.statement.controls.agree.valueChanges.subscribe((value) => {
        if (this.statement.controls.disagree.value && value) {
          this.statement.controls.disagree.setValue(!value);
        }
      })
    );

    this.subscriptions.push(
      this.statement.controls.disagree.valueChanges.subscribe((value) => {
        if (this.statement.controls.agree.value && value) {
          this.statement.controls.agree.setValue(!value);
        }
      })
    );
  }

  private getDefectInspectionNamesFormArray(defect: DefectDto | AggregatedDefectDto): FormArray {
    if (defect.inspectionElementNames === null) {
      return null;
    }

    const inspectionNames = this.formBuilder.array<FormGroup>([]);
    defect.inspectionElementNames.forEach((inspectionName) => {
      inspectionNames.push(this.formBuilder.group<I18NText>(inspectionName));
    });
    return inspectionNames as FormArray;
  }

  private getDefectSanctionSuggestionsFormArray(
    defect: DefectDto | AggregatedDefectDto
  ): FormArray {
    if (
      typeof defect.sanctionSuggestions === 'undefined' ||
      defect.sanctionSuggestions === null ||
      defect.sanctionSuggestions.length <= 0
    ) {
      return null;
    }
    const sanctionSuggestions = this.formBuilder.array<FormGroup>([]);
    defect.sanctionSuggestions.forEach((sanctionSuggestion) => {
      sanctionSuggestions.push(this.formBuilder.group<I18NText>(sanctionSuggestion));
    });
    return sanctionSuggestions;
  }

  private ensureBooleanValue(value): boolean {
    if (typeof value !== 'boolean') {
      return false as boolean;
    }
    return value as boolean;
  }

  private ensureDateValue(value: any): Date {
    if (value instanceof Date || typeof value !== 'string') {
      return null;
    }
    return new Date(value);
  }

  private getDefectSubDefectsFormArray(aggregateDefect: AggregatedDefectDto): FormArray {
    if (
      aggregateDefect === null ||
      typeof aggregateDefect.defects === 'undefined' ||
      aggregateDefect.defects === null ||
      aggregateDefect.defects.length <= 0
    ) {
      return null;
    }

    const subDefects = this.formBuilder.array<FormGroup>([]);
    aggregateDefect.defects.forEach((subDefect) => {
      const subDefectInspectionElement = subDefect.inspectionElement;
      subDefects.push(
        this.formBuilder.group({
          inspectionElementNames: this.getDefectInspectionNamesFormArray(subDefect),
          pointNumber: subDefect.pointNumber,
          result: subDefect.result,
          showDefectSeveritySelection: subDefect.showDefectSeveritySelection,
          sanctionSuggestions: this.getDefectSanctionSuggestionsFormArray(subDefect),
          inspectionElement: this.getInspectionElementFormGroup(subDefectInspectionElement),
          label: subDefect.label,
          defects: subDefect.defects,
          detailsCollapsed: subDefect.detailsCollapsed,
        })
      );
    });
    return subDefects;
  }

  private getInspectionElementFormGroup(inspectionElement: InspectionElementLinkedDto): FormGroup {
    if (inspectionElement === null || typeof inspectionElement === 'undefined') {
      return null;
    }

    return this.formBuilder.group({
      inspectionElement: inspectionElement.inspectionElement,
      childElements:
        inspectionElement.childElements === null ? inspectionElement.childElements : [],
      parentInspectionElement: this.getInspectionElementFormGroup(
        inspectionElement.parentInspectionElement
      ),
      inspectionCategory: inspectionElement.inspectionCategory,
    });
  }

  private getDefectResultFormGroup(defect: DefectDto): FormGroup {
    const defectResult = defect.result;
    if (defect.result === null || typeof defect.result === 'undefined') {
      return this.formBuilder.group<FlatInspectionResultDto>({
        fieldValue: null,
        inspectionElementKey: null,
        inspectionResult: null,
      });
    }
    return this.formBuilder.group<FlatInspectionResultDto>({ ...defectResult });
  }

  private buildInspectionCategoriesChain(elements: InspectionElementDto[]): void {
    this.allCategoriesChain = this.getInspectionElementsLinked(elements, null, null);
  }

  private loadClientConfig() {
    this.clientConfigDBService.getClientConfig().subscribe(
      (clientConfig) => {
        if (clientConfig === null || clientConfig.protocol === null) {
          const message = this.translateService.instant(
            'Bitte verbinden Sie sich zuerst mit AControl.'
          );
          const ensureProtocolConfigText = this.translateService.instant('Error.Config');
          this.alerts.danger(`${message} ${ensureProtocolConfigText}`, null);
          return;
        }

        this.clientConfig = clientConfig;
        this.loadClientConfigFees(clientConfig);
        this.loadClientConfigFurtherActions(clientConfig);
        this.loadClientConfigInspectionRemarkSuggestion(clientConfig);
        this.loadClientConfigDisplayInspectionRemarkInProtocolPdf(clientConfig);
      },
      () => {}
    );
  }

  private loadClientConfigFurtherActions(clientConfig: ClientConfig) {
    if (
      clientConfig.protocol.furtherAction === null ||
      typeof clientConfig.protocol.furtherAction === 'undefined'
    ) {
      return;
    }
    const furtherAction = clientConfig.protocol.furtherAction;
    this.loadClientConfigFurtherActionFurtherAction(furtherAction.furtherActions);
    this.loadClientConfigFurtherActionNotifications(furtherAction.notifications);
  }

  private loadClientConfigFurtherActionFurtherAction(furtherActions: TranslationText[]) {
    if (furtherActions === null || typeof furtherActions === 'undefined') {
      return;
    }
    furtherActions.forEach((item) => {
      this.configProtocolFurtherActions.push(this.getTranslationText(item));
    });
  }

  private loadClientConfigFurtherActionNotifications(notifications: TranslationText[]) {
    if (notifications === null || typeof notifications === 'undefined') {
      return;
    }
    notifications.forEach((item) => {
      this.configProtocolNotifications.push(this.getTranslationText(item));
    });
  }

  private loadClientConfigFees(clientConfig: ClientConfig): void {
    if (clientConfig.protocol.fees == null || clientConfig.protocol.fees.feesTypes == null) {
      this.hasFees = false;
      this.setOptionNotDisplayFeesOnProtocol();
      return;
    }
    this.hasFees = true;
    this.loadClientConfigFeesPositions(clientConfig.protocol.fees.positions);
    this.loadClientConfigFeesFeesTypes(clientConfig.protocol.fees.feesTypes);
  }

  private setOptionNotDisplayFeesOnProtocol(): void {
    this.fees.controls.displayFeesOnProtocol.setValue(2);
  }

  private loadClientConfigFeesPositions(positions: Position[]) {
    if (positions === null || typeof positions === 'undefined' || positions.length <= 0) {
      const configTypeName = this.translateService.instant('Gebührenpositionen');
      this.displayConfigProtocolMessage(configTypeName);
      return;
    }

    const protocolFees = this.formBuilder.array<FormGroup>([]);
    let counter = 0;
    const configFees = new Array<ProtocolFeeDto>();
    positions.forEach((configFeePosition) => {
      const isFeeSelected = this.getFeeSelectionValue(counter);
      const protocolFeeDto = {
        selected: this.ensureBooleanValue(isFeeSelected),
        text: this.getTranslationText(configFeePosition),
        rate: this.getOnlyDigits(configFeePosition.rateDe),
        hoursFee: this.getFeeHoursFeeValue(isFeeSelected, counter),
        hoursReason: this.getHoursReasonFeeValue(isFeeSelected, counter),
        hours: this.getHoursFeeValue(isFeeSelected, counter),
      };

      configFees.push(protocolFeeDto);

      protocolFees.push(
        this.formBuilder.group<ProtocolFeeDto>({
          ...protocolFeeDto,
        })
      );
      counter += 1;
    });
    this.fees.controls.positions = protocolFees;
    this.configProtocolFees = configFees;
  }

  private loadClientConfigFeesFeesTypes(feesTypes: Array<TranslationText>): void {
    if (feesTypes === null || typeof feesTypes === 'undefined' || feesTypes.length <= 0) {
      const configTypeName = this.translateService.instant('Gebührenarten');
      this.displayConfigProtocolMessage(configTypeName);
      return;
    }

    feesTypes.forEach((item) => {
      this.configProtocolFeesType.push(this.getTranslationText(item));
    });
  }

  private loadClientConfigInspectionRemarkSuggestion(clientConfig: ClientConfig) {
    if (clientConfig.protocol.inspectionRemarkSuggestions == null) {
      return;
    }
    const inspectionRemarkSuggestions = clientConfig.protocol.inspectionRemarkSuggestions;
    inspectionRemarkSuggestions.forEach((item: InspectionRemarkSuggestion) => {
      this.configProtocolInspectionRemarkSuggestions.push(this.getTranslationText(item));
    });
  }

  private loadClientConfigDisplayInspectionRemarkInProtocolPdf(clientConfig: ClientConfig): void {
    if (this.inspection.value.displayInspectionRemarkInProtocolPdf === null) {
      if (clientConfig.protocol.displayInspectionRemarkInProtocolPdfDefaultValue != null) {
        const defaultValue = clientConfig.protocol.displayInspectionRemarkInProtocolPdfDefaultValue;
        this.inspection.controls.displayInspectionRemarkInProtocolPdf.setValue(defaultValue);
      } else {
        this.inspection.controls.displayInspectionRemarkInProtocolPdf.setValue(true);
      }
    }
  }

  private displayConfigProtocolMessage(configTypeName: string): void {
    const message = this.translateService.instant('ConfigProtocol.Message', { configTypeName });
    const ensureProtocolConfigText = this.translateService.instant('Error.Config');
    this.alerts.danger(`${message} ${ensureProtocolConfigText}`, null);
  }

  private getFeeSelectionValue(index: number): boolean {
    const protocolFeeDto = this.getProtocolFeePositionDto(index);
    return protocolFeeDto !== null && protocolFeeDto.selected !== null
      ? protocolFeeDto.selected
      : false;
  }

  private getFeeHoursFeeValue(isFeeSelected: boolean, index: number): number {
    if (!isFeeSelected) {
      return 0;
    }
    const protocolFeeDto = this.getProtocolFeePositionDto(index);
    return protocolFeeDto !== null && protocolFeeDto.hoursFee !== null
      ? protocolFeeDto.hoursFee
      : 0;
  }

  private getHoursFeeValue(isFeeSelected: boolean, index: number): number {
    if (!isFeeSelected) {
      return 0;
    }
    const protocolFeeDto = this.getProtocolFeePositionDto(index);
    return protocolFeeDto !== null && protocolFeeDto.hours !== null ? protocolFeeDto.hours : 0;
  }

  private getHoursReasonFeeValue(isFeeSelected: boolean, index: number): string {
    if (!isFeeSelected) {
      return '';
    }
    const protocolFeeDto = this.getProtocolFeePositionDto(index);
    return protocolFeeDto !== null ? protocolFeeDto.hoursReason : '';
  }

  private getProtocolFeePositionDto(index: number): ProtocolFeeDto {
    if (this.protocol.fees.positions === null || this.protocol.fees.positions.length <= 0) {
      return null;
    }

    const protocolFeePositionDto = this.protocol.fees.positions[index];
    if (typeof protocolFeePositionDto === 'undefined' || protocolFeePositionDto === null) {
      return null;
    }
    return protocolFeePositionDto;
  }

  private getTranslationText(translationText: TranslationText): I18NText {
    return {
      germanText: translationText.textDe,
      frenchText: translationText.textFr,
    };
  }

  private getDefaultTextValue(translationTexts: TranslationText[], i18NTexts: I18NText[]): string {
    const defaultValue = translationTexts.filter((p) => p.isDefaultValue === true);
    if (defaultValue && defaultValue.length > 0) {
      const selectedTexts = i18NTexts.filter(
        (p) => p.germanText === defaultValue[0].textDe && p.frenchText === defaultValue[0].textFr
      );
      if (selectedTexts && selectedTexts.length > 0) {
        return this.i18nTextPipe.transform(selectedTexts[0]);
      }
    }
    return null;
  }

  private getOnlyDigits(value: string): number {
    const regex = /[+-]?\d+(\.\d+)?/g;
    const matches = value.replace(/-/g, '0').match(regex);
    const numberValue = matches[0];
    return +this.convertToFloat(numberValue);
  }

  private updateFeesTotal(): void {
    let total = 0;
    const updateFeeTotals = (feeGroup: FormGroup): void => {
      total += +parseFloat(feeGroup.controls.hoursFee.value).toFixed(2);
    };

    this.iterateFeeGroups(updateFeeTotals);
    this.fees.controls.totalFee.setValue(this.convertToFloat(total));
  }

  private iterateFeeGroups(action: (fee: FormGroup) => void): void {
    const configFees = this.fees.controls.positions as FormArray;
    for (const control of configFees.controls) {
      const feeGroup = control as FormGroup;
      action(feeGroup);
    }
  }

  private getRateByIndex(feeIndex: number): string {
    return this.convertToFloat(this.configProtocolFees[feeIndex].rate);
  }

  private convertToFloat(value: string | number): string {
    if (typeof value === 'string') {
      return parseFloat(value).toFixed(2);
    }
    return parseFloat(String(value)).toFixed(2);
  }

  private getPositionAsDto(index: number): ProtocolFeeDto {
    return this.fees.controls.positions.value[index] as ProtocolFeeDto;
  }

  private getFeePositionFormGroupByindex(index: number): FormGroup {
    const feeArray = this.fees.controls.positions as FormArray;
    return feeArray.controls[index] as FormGroup;
  }

  private getInspectionElementsLinked(
    elements: InspectionElementDto[],
    parentInspectionElement: InspectionElementLinkedDto,
    inspectionCategory: InspectionElementDto
  ): InspectionElementLinkedDto[] {
    const list = new Array<InspectionElementLinkedDto>();
    if (elements === null) {
      return null;
    }
    elements.forEach((element) => {
      const linkedElement = new InspectionElementLinkedDto();
      linkedElement.inspectionElement = element;
      linkedElement.parentInspectionElement = parentInspectionElement;
      linkedElement.inspectionCategory = inspectionCategory === null ? element : inspectionCategory;
      linkedElement.childElements = this.getInspectionElementsLinked(
        element.childElements,
        linkedElement,
        linkedElement.inspectionCategory
      );
      list.push(linkedElement);
    });
    return list;
  }

  private initializeFurtherActionControlsChanges() {
    this.furtherAction.controls.notification.valueChanges.subscribe((value) => {
      if (value === false) {
        this.furtherAction.controls.notificationText.setValue(null);
        this.furtherAction.controls.notificationText.disable();
        return;
      }
      this.furtherAction.controls.notificationText.enable();
      const defaultTextValue = this.getDefaultTextValue(
        this.clientConfig.protocol.furtherAction.notifications,
        this.configProtocolNotifications
      );
      this.furtherAction.controls.notificationText.setValue(defaultTextValue);
    });

    this.furtherAction.controls.furtherAction.valueChanges.subscribe((value) => {
      if (value === false) {
        this.furtherAction.controls.furtherActionText.setValue(null);
        this.furtherAction.controls.furtherActionText.disable();
        return;
      }
      this.furtherAction.controls.furtherActionText.enable();
      const defaultTextValue = this.getDefaultTextValue(
        this.clientConfig.protocol.furtherAction.furtherActions,
        this.configProtocolFurtherActions
      );
      this.furtherAction.controls.furtherActionText.setValue(defaultTextValue);
    });
  }

  private initializeCaches(): void {
    const categoryConfig = this.route.snapshot.data['categoryConfig'] as CategoryConfig;
    const inspectionStyles = this.route.snapshot.data['inspectionStyles'] as InspectionStyleDto[];
    const inspectionStates = this.route.snapshot.data['inspectionStates'] as InspectionStatusDto[];
    this.inspectionCategoryCacheService.setCache(categoryConfig, this.allCategories);
    this.enumCacheService.setCache(inspectionStyles, inspectionStates);
  }

  private initializeProtocol(): void {
    if (!this.protocol || !this.protocol.hasOwnProperty('statement')) {
      this.protocol = {
        inspectionKey: this.inspectionWithResult.inspectionKey,
        inspectionStartDate: this.getRoundedCurrentTime(),
        inspectionEndDate: this.getRoundedCurrentTime(),
        controlledArea: this.getControlledArea(),
        controlledLocation: null,
        inspectionStyle: null,
        inspectionDate: null,
        defects: null,
        feesSelection: 0,
        displayFieldValueElements: this.getDisplayFieldValueElements(),
        furtherAction: {
          noFurtherAction: false,
          repairsIndependently: false,
          feedback: false,
          inspectionReport: false,
          order: false,
          criminalComplaint: false,
          notification: false,
          followupInspection: false,
          furtherAction: false,
          notificationText: null,
          followupInspectionDate: null,
          furtherActionText: null,
        },
        fees: {
          feesSelection: 0,
          positions: [],
          feesTypes: [],
          totalFee: 0,
          displayFeesOnProtocol: 0,
        },
        statement: {
          correspondToFacts: false,
          noCorrespondToFacts: false,
          agree: false,
          disagree: false,
          remark: null,
        },
        displayInspectionRemarkInProtocolPdf: null,
      };
    } else {
      this.protocol.controlledArea = this.getControlledArea();
      this.protocol.displayFieldValueElements = this.getDisplayFieldValueElements();
    }
  }

  private saveProtocol(): void {
    this.protocol = { ...this.protocolForm.value };

    this.protocol.inspectionStartDate = this.inspection.value.inspectionStartDate;
    this.protocol.inspectionEndDate = this.inspection.value.inspectionEndDate;
    this.protocol.controlledLocation = this.inspection.value.controlledLocation;
    this.protocol.controlledArea = this.inspection.value.controlledArea;
    this.protocol.displayInspectionRemarkInProtocolPdf =
      this.inspection.value.displayInspectionRemarkInProtocolPdf;

    this.protocolDBService.putProtocol(this.protocol).subscribe(
      (success: string) => {
        console.log(success);
      },
      (err: any) => {
        console.log(err);
        this.alerts.danger(
          this.translateService.instant('Das Kontrollprotokoll konnten nicht gespeichert werden.'),
          err
        );
      }
    );
  }

  private saveInspection(): void {
    this.defects = [];
    for (const defect of this.defectArray.value) {
      this.defects.push(defect as DefectDto);
      this.setDefectByInspectionElementKey(defect.result);
    }

    this.inspectionWithResult.inspectionStyle = this.inspection.value.inspectionStyle;
    this.inspectionWithResult.inspectionRemark = this.inspection.value.inspectionRemark;
    this.inspectionWithResult.inspectorName = this.inspection.value.inspectorName;

    this.handleAggregateDefectsOnSave();
    this.inspectionDBService.putInspectionWithResults(this.inspectionWithResult).subscribe(
      (success: string) => {
        console.log(success);
        this.readonly = this.isStatusEntered();
      },
      (err: any) => {
        console.log(err);
        this.alerts.danger(
          this.translateService.instant('Die Kontrolle konnten nicht gespeichert werden.'),
          err
        );
      }
    );
  }

  private setDefectByInspectionElementKey(defectResult: FlatInspectionResultDto): void {
    let isBreaked = false;
    for (const categoryResult of this.inspectionWithResult.categoryResults) {
      for (let resultIndex = 0; resultIndex < categoryResult.results.length; resultIndex++) {
        const result = categoryResult.results[resultIndex];
        if (result.inspectionElementKey === defectResult.inspectionElementKey) {
          categoryResult.results[resultIndex] = { ...defectResult };
          isBreaked = true;
          break;
        }
      }
      if (isBreaked) {
        break;
      }
    }
  }

  private getNgbDate(ngbDate: NgbTimeStruct): NgbTimeStruct {
    if (ngbDate !== null) {
      return ngbDate;
    }

    return this.getRoundedCurrentTime();
  }

  private getRoundedCurrentTime(): NgbTimeStruct {
    const date = new Date();
    if (date.getMinutes() < 15) {
      date.setMinutes(15);
    } else if (date.getMinutes() < 30) {
      date.setMinutes(30);
    } else if (date.getMinutes() < 45) {
      date.setMinutes(45);
    } else {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: 0,
    };
  }

  private buildDefects(): void {
    this.defects = new Array<DefectDto>();
    if (this.inspectionWithResult.categoryResults) {
      this.inspectionWithResult.categoryResults.forEach((categoryResult) => {
        const categoryElement = this.allCategoriesChain.find(
          (c) => c.inspectionElement.number === categoryResult.categoryNumber
        );
        const categoryName =
          this.inspectionCategoryCacheService.getInspectionCategoryName(categoryResult);
        this.buildDefectsOfElementAndChildren(
          categoryElement,
          new Array<I18NText>(),
          categoryResult.results,
          categoryName,
          categoryElement.inspectionCategory
        );
      });
      this.aggregateDefects();
      this.defects = _.sortBy(this.defects, (defect: DefectDto) => {
        const localizedName = this.i18nTextPipe.transform(defect.inspectionElementNames[0]);
        let sortString = localizedName ? localizedName.toLowerCase() : '';
        sortString += defect instanceof AggregatedDefectDto ? '0' : '1';
        sortString += defect.pointNumber;
        return sortString;
      });
    }
  }

  private buildDefectsOfElementAndChildren(
    inspectionElement: InspectionElementLinkedDto,
    parentInspectionElementNames: I18NText[],
    results: FlatInspectionResultDto[],
    categoryName: I18NText,
    inspectionCategory: InspectionElementDto
  ): void {
    const result = results.find(
      (r) => r.inspectionElementKey === inspectionElement.inspectionElement.key
    );
    const inspectionElementNames =
      inspectionElement.inspectionElement.type === InspectionResultElementType.Category
        ? [categoryName]
        : this.getInspectionElementNames(
            parentInspectionElementNames,
            inspectionElement.inspectionElement
          );
    if (
      result &&
      inspectionElement.inspectionElement.type === InspectionResultElementType.Point &&
      (result.inspectionResult === InspectionResultType.Defect ||
        result.inspectionResult === InspectionResultType.DefectInactive)
    ) {
      const defect = {
        inspectionElementNames,
        pointNumber: inspectionElement.inspectionElement.number,
        result,
        showDefectSeveritySelection:
          inspectionElement.inspectionElement.showDefectSeveritySelection,
        inspectionElement,
        sanctionSuggestions: null,
        label: null,
        defects: null,
        detailsCollapsed: false,
      };
      if (this.pointConfig && this.pointConfig.points) {
        const groupElement = this.findInspectionElementGroup(inspectionElement);
        let groupNumber: string = null;
        if (groupElement !== null) {
          groupNumber = groupElement.inspectionElement.number;
        }
        defect.sanctionSuggestions = this.getSanctionSuggestions(
          inspectionCategory,
          inspectionElement,
          groupNumber
        );
      }

      this.defects.push(defect);
    }
    if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach((childElement) => {
        this.buildDefectsOfElementAndChildren(
          childElement,
          inspectionElementNames,
          results,
          categoryName,
          inspectionCategory
        );
      });
    }
  }

  private getSanctionSuggestions(
    inspectionCategory: InspectionElementDto,
    inspectionElement: InspectionElementLinkedDto,
    groupNumber: string
  ): I18NText[] {
    let points: Point[];
    if (this.pointConfig) {
      const pointsCategoryPoint = this.pointConfig.points.filter(
        (p) =>
          p.categoryNumber === inspectionCategory.number &&
          p.pointNumber === inspectionElement.inspectionElement.number
      );
      points = pointsCategoryPoint.filter((p) => p.pointGroupNumber === groupNumber);
      if (points.length === 0) {
        points = pointsCategoryPoint.filter((p) => p.pointGroupNumber === null);
      }
    }

    let sanctionSuggestions: I18NText[];
    if (points && points.length > 0 && points[0].sanctionSuggestions) {
      sanctionSuggestions = points[0].sanctionSuggestions.map((d) => ({
        germanText: d.textDe,
        frenchText: d.textFr,
        italianText: null,
      }));
    }
    return sanctionSuggestions;
  }

  private findInspectionElementGroup(
    inspectionElement: InspectionElementLinkedDto
  ): InspectionElementLinkedDto {
    if (
      inspectionElement.inspectionElement.type === InspectionResultElementType.PointGroup &&
      inspectionElement.inspectionElement.pointGroupType === PointGroupType.Regular
    ) {
      return inspectionElement;
    }
    if (inspectionElement.parentInspectionElement === null) {
      return null;
    }
    return this.findInspectionElementGroup(inspectionElement.parentInspectionElement);
  }

  private aggregateDefects(): void {
    const aggregatedDefects = new Array<DefectDto>();
    const aggregatedDefectKeysSet = new Set<string>();
    this.defects.forEach((defect) => {
      if (!aggregatedDefectKeysSet.has(defect.result.inspectionElementKey)) {
        const identicalDefects = this.getIdenticalDefects(defect);
        if (identicalDefects.length) {
          const aggregatedDefect = new AggregatedDefectDto();
          aggregatedDefect.inspectionElementNames = this.getAggregatedDefectElementNames(defect);
          aggregatedDefect.pointNumber = defect.pointNumber;
          aggregatedDefect.showDefectSeveritySelection = defect.showDefectSeveritySelection;
          aggregatedDefect.result = Object.assign({}, defect.result);
          aggregatedDefect.defects = [defect, ...identicalDefects];
          aggregatedDefect.detailsCollapsed = true;
          aggregatedDefect.sanctionSuggestions = this.getSanctionSuggestions(
            defect.inspectionElement.inspectionCategory,
            defect.inspectionElement,
            null
          );
          aggregatedDefect.defects.forEach((d) => {
            aggregatedDefectKeysSet.add(d.result.inspectionElementKey);
          });
          aggregatedDefects.push(aggregatedDefect);
        } else {
          aggregatedDefects.push(defect);
        }
      }
    });
    this.defects = aggregatedDefects;
  }

  private getIdenticalDefects(defect: DefectDto): DefectDto[] {
    return this.defects.filter(
      (otherDefect) =>
        otherDefect.result.inspectionElementKey !== defect.result.inspectionElementKey &&
        otherDefect.pointNumber === defect.pointNumber &&
        otherDefect.inspectionElementNames[0].germanText ===
          defect.inspectionElementNames[0].germanText &&
        otherDefect.result.defectDescription === defect.result.defectDescription
    );
  }

  private getAggregatedDefectElementNames(defect: DefectDto): I18NText[] {
    const elementNames = new Array<I18NText>();
    for (let i = 0; i < defect.inspectionElementNames.length; i++) {
      if (i !== 1) {
        elementNames.push(defect.inspectionElementNames[i]);
      }
    }
    return elementNames;
  }

  private getInspectionElementNames(
    parentInspectionElementNames: I18NText[],
    inspectionElement: InspectionElementDto
  ): I18NText[] {
    const inspectionElementNames = Object.assign(
      new Array<I18NText>(),
      parentInspectionElementNames
    );
    if (inspectionElement.type !== InspectionResultElementType.Point) {
      inspectionElementNames.push(inspectionElement.name);
    }
    return inspectionElementNames;
  }

  private validateInspectionForStatusChange(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const notDefinedPointsMap = this.statusChangeValidationService.getNotDefinedPointsMap(
        this.inspectionWithResult
      );
      const categoriesWithMissingDefectSeverity =
        this.statusChangeValidationService.getCategoriesWithMissingDefectSeverity(
          this.inspectionWithResult
        );
      const furtherActionSelectionValid =
        this.statusChangeValidationService.isFurtherActionSelectionValid(
          this.protocol.furtherAction
        );
      if (
        notDefinedPointsMap.size ||
        categoriesWithMissingDefectSeverity.length ||
        !furtherActionSelectionValid
      ) {
        const statusChangeModal = this.modalService.open(StatusChangeDialogComponent, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        });
        statusChangeModal.componentInstance.notDefinedPointsMap = notDefinedPointsMap;
        statusChangeModal.componentInstance.categoriesWithMissingDefectSeverity =
          categoriesWithMissingDefectSeverity;
        statusChangeModal.componentInstance.furtherActionSelectionValid =
          furtherActionSelectionValid;
        statusChangeModal.result.then(
          () => {
            observer.next(true);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  private validateAllocateFeesToProtocolChange(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!this.displaySectionFees() || this.fees.controls.displayFeesOnProtocol.value > 0) {
        observer.next(true);
        observer.complete();
      } else {
        const modal = this.modalService.open(YesNoDialogComponent, {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-dialog-md',
        });
        modal.componentInstance.title = this.translateService.instant('ConfirmAllocateFeesTitle');
        modal.componentInstance.text = this.translateService.instant('ConfirmAllocateFeesContent');
        modal.result.then(
          () => {
            this.fees.controls.displayFeesOnProtocol.setValue(1);
            observer.next(true);
            observer.complete();
          },
          () => {
            this.fees.controls.displayFeesOnProtocol.setValue(2);
            observer.next(true);
            observer.complete();
          }
        );
      }
    });
  }

  private validateInspectionDateForStatusChange(): Observable<boolean> {
    return this.validateOkCancelDialog(
      !this.isInspectionDateToday(),
      this.translateService.instant('ConfirmDateTitle'),
      this.translateService.instant('ConfirmDateContent')
    );
  }

  private validateResultComplaintRemedyDateDueDates(): Observable<boolean> {
    return this.validateOkCancelDialog(
      this.hasComplaintRemedyDateDueDatesInPast(),
      this.translateService.instant('ConfirmDeadlineTitle'),
      this.translateService.instant('ConfirmDeadlineContent')
    );
  }

  private validateFurtherActionNotification(): Observable<boolean> {
    const showDialog =
      this.protocol.furtherAction.notification && !this.protocol.furtherAction.notificationText;
    return this.validateOkCancelDialog(
      showDialog,
      this.translateService.instant('Weiteres Vorgehen'),
      this.translateService.instant('ConfirmFurtherActionNotification')
    );
  }

  private validateFurtherActionText(): Observable<boolean> {
    const showDialog =
      this.protocol.furtherAction.furtherAction && !this.protocol.furtherAction.furtherActionText;
    return this.validateOkCancelDialog(
      showDialog,
      this.translateService.instant('Weiteres Vorgehen'),
      this.translateService.instant('ConfirmFurtherActionText')
    );
  }

  private validateOkCancelDialog(
    showDialog: boolean,
    title: string,
    text: string
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!showDialog) {
        observer.next(true);
        observer.complete();
      } else {
        const okCancelModal = this.modalService.open(OkCancelDialogComponent, {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-dialog-md',
        });
        okCancelModal.componentInstance.title = this.translateService.instant(title);
        okCancelModal.componentInstance.text = this.translateService.instant(text);
        okCancelModal.result.then(
          () => {
            observer.next(true);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      }
    });
  }

  private hasComplaintRemedyDateDueDatesInPast(): boolean {
    if (
      this.protocolForm.value.defects === null ||
      typeof this.protocolForm.value.defects === 'undefined'
    ) {
      return false;
    }

    let resultIsInPast = false;
    this.protocolForm.value.defects.forEach((defect) => {
      if (defect !== null && defect.result !== null) {
        if (defect.result.complaintRemedyDateDue !== null) {
          if (defect.result.complaintRemedyDateDue.asDate().isInPast()) {
            resultIsInPast = true;
            return;
          }
        }
      }
    });
    return resultIsInPast;
  }

  private isInspectionDateToday(): boolean {
    let isInspectionDateToday = true;
    const today = new Date();
    if (!this.inspectionWithResult.inspectionDate) {
      isInspectionDateToday = false;
    } else {
      const inspectionDate = new Date(this.inspectionWithResult.inspectionDate);
      isInspectionDateToday =
        inspectionDate.getFullYear() === today.getFullYear() &&
        inspectionDate.getMonth() === today.getMonth() &&
        inspectionDate.getDate() === today.getDate();
    }
    return isInspectionDateToday;
  }

  private validateInspectionTimeForStatusChange(): Observable<boolean> {
    return this.validateOkCancelDialog(
      !this.isStartTimeBeforeEndTime(),
      this.translateService.instant('ConfirmTimesTitle'),
      this.translateService.instant('ConfirmTimesContent')
    );
  }

  private isStartTimeBeforeEndTime(): boolean {
    if (this.inspection.value.inspectionStartDate && this.inspection.value.inspectionEndDate) {
      const startHour = this.inspection.value.inspectionStartDate.hour;
      const endHour = this.inspection.value.inspectionEndDate.hour;
      if (endHour < startHour) {
        return false;
      } else if (endHour === startHour) {
        const startMinute = this.inspection.value.inspectionStartDate.minute;
        const endMinute = this.inspection.value.inspectionEndDate.minute;
        if (endMinute <= startMinute) {
          return false;
        }
      }
    }
    return true;
  }

  private handleAggregateDefectsOnSave(): void {
    this.defects.forEach((defect) => {
      if (defect.isAggregate) {
        const aggregatedDefect = defect as AggregatedDefectDto;
        aggregatedDefect.defects.forEach((subDefect) => {
          subDefect.result.amountAffected = aggregatedDefect.result.amountAffected;
          subDefect.result.complaintRemedyDateDue = aggregatedDefect.result.complaintRemedyDateDue;
          subDefect.result.defectDescription = aggregatedDefect.result.defectDescription;
          subDefect.result.defectRepetition = aggregatedDefect.result.defectRepetition;
          subDefect.result.defectSeverity = aggregatedDefect.result.defectSeverity;
          subDefect.result.livestockOwnerFeedbackDateDue =
            aggregatedDefect.result.livestockOwnerFeedbackDateDue;
          subDefect.result.remark = aggregatedDefect.result.remark;
        });
      }
    });
  }

  private getInspectionStatus(statusAsEnum: InspectionStatus): InspectionStatusDto {
    return this.enumCacheService
      .getInspectionStates()
      .find((status) => status.asEnum === statusAsEnum);
  }

  private getDisplayFieldValueElements(): Point[] {
    const displayFieldValues = new Array<Point>();
    if (this.pointConfig == null || this.pointConfig.points == null) {
      return displayFieldValues;
    }
    const elements = this.pointConfig.points.filter((p) => p.displayFieldValueInProtocolPdf);
    elements.forEach((element) => {
      if (element) {
        displayFieldValues.push(element);
      }
    });
    return displayFieldValues;
  }

  private displayDefectSeverityInProtocolPdf(): boolean {
    if (this.inspectionWithResult.inspectionTypeName) {
      if (this.inspectionTypeConfig && this.inspectionTypeConfig.inspectionTypes) {
        const typeConfig = this.inspectionTypeConfig.inspectionTypes.filter(
          (it) => it.acontrolNameDe === this.inspectionWithResult.inspectionTypeName.germanText
        );
        if (
          typeConfig &&
          typeConfig.length > 0 &&
          typeConfig[0].displayDefectSeverityInProtocolPdf === false
        ) {
          return false;
        }
      }
    }
    return true;
  }

  private displayInspectionReason(): boolean {
    return this.inspectionWithResult.inspectionReasonName.germanText.includes(',');
  }

  private getFeePositionsFormArray(): FormArray {
    if (this.protocol.fees.positions instanceof Array) {
      const positions = this.formBuilder.array<FormGroup>([]);
      this.protocol.fees.positions.forEach((position) => {
        positions.push(
          this.formBuilder.group({
            selected: position.selected,
            hoursFee: position.hoursFee,
            hours: position.hours,
            hoursReason: position.hoursReason,
            text: position.text,
            rate: position.rate,
          })
        );
      });
      return positions;
    }
  }

  private getAllowedNotControlledPointsInControlledCategory(
    inspectionElements: InspectionElementDto[],
    categoryNumber: string = null,
    pointGroupNumber: string = null
  ): InspectionElementDto[] {
    /*PDF Bereich Kontrollierte Bereiche ohne Mängel: Es soll zu bestimmten Rubriken möglich sein,
    diese auch dann im PDF aufzulisten, wenn einzelne Kontrollpunkte NK sind. Die Punktegruppe ist
    dabei irrelevant, falls in der Konfiguration NK Punkte definiert werden, gelten sie über die
    ganze Rubrik. */
    const allowedInspectionElements = new Array<InspectionElementDto>();
    if (!inspectionElements || inspectionElements.length === 0 || this.pointConfig == null || this.pointConfig.points == null) {
      return allowedInspectionElements;
    }
    inspectionElements.forEach((element) => {
      let elements = new Array<InspectionElementDto>();
      if (element.type === InspectionResultElementType.Category) {
        categoryNumber = element.number;
        elements = this.getAllowedNotControlledPointsInControlledCategory(
          element.childElements,
          categoryNumber,
          pointGroupNumber
        );
      } else if (element.type === InspectionResultElementType.PointGroup) {
        pointGroupNumber = element.number;
        elements = this.getAllowedNotControlledPointsInControlledCategory(
          element.childElements,
          categoryNumber,
          pointGroupNumber
        );
      } else if (element.type === InspectionResultElementType.Point) {
        const allowedPoints = this.pointConfig.points.filter(
          (p) => p.isAllowedToBeNotControlledInControlledCategory
        );
        const allowedPoint = allowedPoints.some(
          (point) =>
            point.categoryNumber === categoryNumber &&
            point.pointNumber === element.number
        );
        if (allowedPoint) {
          elements.push(element);
        }
      }
      if (elements && elements.length > 0) {
        elements.forEach((e) => allowedInspectionElements.push(e));
      }
    });
    return allowedInspectionElements;
  }
}
