import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfAgService } from './protocol-pdf-ag.service';
import { PdfDocumentParagraph } from './pdf-document-paragraph';

export class ProtocolPdfAgBienenService extends ProtocolPdfAgService {
  protected showDocumentationCheckboxes = false;
  protected headerAddress = 'Obere Vorstadt 14, 5000 Aarau; Telefon: 062 835 29 70; E-Mail: veterinaerdienst@ag.ch';

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const signatureBlockHeight = 4 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionValidWithoutSignature'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    const signatureBlockHeight = 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const pdfDocumentParagraph = new PdfDocumentParagraph();
    pdfDocumentParagraph.headerFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.bodyFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.positionY = baseY;
    pdfDocumentParagraph.prefix = '-';
    pdfDocumentParagraph.multiLinesPrefix = ' ';
    pdfDocumentParagraph.headerText = this.translateService.instant(
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsAg'
    );
    pdfDocumentParagraph.paragraphTexts = [
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg7'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg6'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg2'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg5'),
    ];
    pdfDocumentParagraph.keepParagraphInSamePage = true;
    return this.writeParagraph(pdfDocumentParagraph);
  }
}
